/**
 * @module login 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
import NormalApis from '@/controllers/NormalApis';

export default {
    /**
     * fetch business profile by business slug
     */
    fetchBusinessProfile(business_slug) {
      const url = `${Env.businessProfileUrl}/api/v1/businesses/slug/${business_slug}?withBranding=true`;
      return NormalApis.getApi(url, {
        'api-token': Env.businessProfileApiToken,
      })
      .then(res => res)
      .catch(err => err);
    },
}