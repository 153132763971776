<template>
  <div class="check-inform-wrapper">

    <template v-if="errorMessage == ''">
      <div class="loading-envelop" v-if="loaded == false">
        <loading></loading>
        <div class="loading-text">
          <loading-text></loading-text>
        </div>
      </div>

      <check-in-form :logo="logo" :location="location" v-else></check-in-form>
    </template>
    <div v-else class="no-business-found">
      <p>{{ errorMessage }}</p>
    </div>
   

  </div>
</template>


<script>
import CheckInForm from '@/components/CheckInForm.vue'
import Loading from '@/components/helpers/Loading.vue'
import LoadingText from '@/components/helpers/LoadingText.vue'
import BusinessProfileApis from '@/controllers/BusinessProfileApis'
import LocationApis from '@/controllers/LocationApis'

export default {
  name: 'App',
  components: {
    CheckInForm,
    Loading,
    LoadingText
  },
  data() {
    return {
      loaded: false,
      businessFound: true,
      locationFound: true,
      logo: '',
      location: {},
    }
  },
  computed: {
    errorMessage() {
      if(this.businessFound == false){
        return 'Sorry we cannot find business detail.';
      } else if(this.locationFound == false){ 
        return 'Sorry we cannot find location detail.';
      } else {
        return '';
      }
    }
  },
  async mounted() {
    // On mount hit api and fetch business
   // Get the business slug
   if(this.$route.params.business_slug){
      let businessProfle = await this.fetchBusinessProfile();
      if(businessProfle){
        // if business exist then only move to location
        if(this.$route.params.business_slug && this.$route.params.location_slug){
           let locationRes = await LocationApis.fetchBySlug(businessProfle.id, this.$route.params.location_slug);
            if(locationRes.status == 200){
              this.location = locationRes.data.payload;
              this.loaded = true;
            } else {
              this.locationFound = false;
            }
        }
      }
   }

  },
  methods: {

    /**
     * Fetch business profile
     */
    async fetchBusinessProfile() {
     let res =  await BusinessProfileApis.fetchBusinessProfile(this.$route.params.business_slug);
     if(res.status == 200){
      // Now set colors and logos
        this.setBrandingColors(res.data.payload);
        this.logo = res.data.payload.branding.logo_url;
        return res.data.payload;
      } else {
        this.businessFound = false;
        return false;
       // no business
      }
    },
    /**
     * Set branding 
     */
    setBrandingColors(data) {
      // set document name
      document.title = data.name;
      let bodyStyles = document.body.style;
      if(data.branding.primary_color){
        bodyStyles.setProperty('--primary-color', data.branding.primary_color);
      }
      if(data.branding.secondary_color){
        bodyStyles.setProperty('--secondary-color', data.branding.secondary_color);
      }
      if(data.branding.secondary_color){
        bodyStyles.setProperty('--accent-color', data.branding.accent_color);
      }
      if(data.branding.font && data.branding.font.family){
          bodyStyles.setProperty('--font-family', data.branding.font.family);
      } 
      if(data.branding.font && data.branding.font.url){
        // if url doesnot have otf or ttf then load from url 
        let fullurlExp = data.branding.font.url.split('.');
        let urlLastVal = fullurlExp.pop();
        if(urlLastVal == 'otf' || urlLastVal == 'ttf' || urlLastVal == 'woff'){
          this.loadInlineCss(data.branding.font)
        } else{ 
          this.loadCss(data.branding.font.url)
        }
      }

    },

    /**
     * Load inline css
     */
    loadInlineCss(font) {
      let ffexist =  document.getElementById('ff-script');
      if (typeof(ffexist) != 'undefined' && ffexist != null){
        // script already exists
        return false;
      }
       new Promise( function( resolve ) {
        let css =`
        @font-face {
          font-family: ${font.family},
          src: url("${font.url}") format("${font.type ? '' : 'opentype'}");
        }`,
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');
        head.appendChild(style);
        style.id = 'ff-script';
        style.type = 'text/css';
        if (style.styleSheet){
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        style.onload = function() { 
          resolve(); 
        };
      } );
    },

    /**
     * Load css
     */
    loadCss( cssURL ) {
      // Get all stylesheet and check if we have already loaded this css
      var ss = document.styleSheets;
      for (var i = 0, max = ss.length; i < max; i++) {
          if (ss[i].href == cssURL)
          return;
      }
      
      return new Promise( function( resolve ) {
        let link = document.createElement( 'link' );
        link.rel  = 'stylesheet';
        link.href = cssURL;
        document.head.appendChild( link );
        link.onload = function() { 
          resolve(); 
        };
      } );
    },

  },
}
</script>

<style>
/**
* primary color -> bg color
* secondary -> button color
* accent -> text color
 */
:root{
  --primary-color: #000; 
  --secondary-color: #8cc63d;
  --accent-color: #fff;
  --font-family: '"SF Pro Text", "sans-serif"';
}
.form-inputs input:focus{
  outline: none;
  border: 1px solid var(--secondary-color) !important; 

}
.no-business-found {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.form-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden; 
    padding-bottom: 40px;
}
.logo-wrapper img {
    height: 100px;
    width: auto;
}
.bg-color,body,.form-inputs input{
  background-color:  var(--primary-color) !important; 
}
.font-color, p, a, li, div,.form-inputs input{
  color:  var(--accent-color) !important; 
}
.btn-color{
   background-color:  var(--secondary-color) !important; 
}
.loading-text {
    position: relative;
    top: -80px;
}
</style>