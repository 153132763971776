<template>
  <div class="form-wrapper">
    <div class="form-container">
      <div class="logo-wrapper">
        <img :src="logo" alt="logo"/>
      </div>
      <div class="welcome-wrapper">
        <p v-if="submitSuccess == false">WELCOME TO {{ location.display_name }}, TO ENSURE THE HEATH AND SAFETY OF OUR GUEST WE ASK THAY YOU PLEASE COMPLETE THE CHECK-IN FORM.</p>
        <p v-else>THANK YOU FOR COMPLETING THE CHECK-IN</p>
      </div>
      <form id="checkinform" class="form-inputs" v-if="submitSuccess == false">
       <p v-if="apiError" class="text-left"><span class="errmsg" v-if="apiError">{{ apiError }}</span></p>
        <p>
          <label for="fname">First Name <span class="errmsg" v-if="error.first_name">{{ error.first_name}}</span></label>
          <input type="text" id="fname" v-model="form.first_name"/>
        </p>
        <p>
          <label for="lname">Last Name <span class="errmsg" v-if="error.last_name">{{ error.last_name}}</span></label>
          <input type="text" id="lname" v-model="form.last_name"/>
        </p>
        <p>
          <label for="phone">Phone Number <span class="errmsg" v-if="error.phone">{{ error.phone}}</span></label>
          <input type="number" id="phone" v-model="form.phone"/>
        </p>
        <p>
          <label for="email">Email</label>
          <input type="text" id="email" v-model="form.email"/>
        </p>
        <button @click.prevent="submitForm()" class="btn-color btn-submit">CHECK-IN</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CheckInApis from '@/controllers/CheckInApis'
export default {
  name: 'CheckinForm',
   components: {
   
  },
  data() {
    return {
      submitSuccess: false,
      apiError: "",
      error: {
        first_name: '',
        last_name: '',
        phone_number: '',
      },
      form: {
        location_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
    }
  },
  props: {
    logo: String,
    location: Object,
  },
  methods: {
    ...mapActions({
      spinner: "spinner/setSpinner",
    }),
    async submitForm() {
      // Reset errors
      this.error = {
        first_name: '',
        last_name: '',
        phone: '',
      };
      this.apiError = '';
      // validate 
      if(this.form.first_name == ''){
        this.error.first_name = 'Please write first name.';
        return;
      } else if(this.form.last_name == '') {
        this.error.last_name = 'Please write last name.';
        return;
      } else if(this.form.phone == '') {
        this.error.phone = 'Please write phone number.';
        return;
      } else if(this.form.phone.length != 10 || this.form.phone.match('/^\d{10}$/') == false) {
        this.error.phone = 'Invalid phone number.';
        return;
      } else {
        // add location id 
        this.form.location_id = this.location.id;
        // trigger
        this.spinner(true);
        let checkInRes = await CheckInApis.insert(this.form);
        if(checkInRes.status == 201){
          document.getElementById("checkinform").reset(); // reset form
          this.submitSuccess = true;
          this.spinner(false);
        } else {
          this.spinner(false);
          this.apiError = checkInRes.response.data.payload[0];
        }
      }
    }
  }
}
</script>

<style>
.form-wrapper {
    width: 320px;
    margin: 0 auto;
}
.form-inputs label{
  text-align: left;
}
.text-left{
  text-align: left;
}
.form-inputs p{
  display: flex;
  flex-direction: column;
}
.form-inputs input{
  width: calc(100% - 12px);
  padding:5px;
  border-radius: 0px;
  border: 1px solid #ccc;
  height: 25px;
}
.form-inputs input:focus{
  outline: none;
}
.btn-submit {
  padding: 11px 26px;
  border-radius: 2px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
.btn-submit:hover{
  opacity: 0.8;
}
span.errmsg {
  color: red;
  font-size: 12px;
  margin-left: 9px;
}
.welcome-wrapper p{
  text-transform: uppercase;
}
@media screen and (min-width: 1024px) {
  .form-wrapper {
      width: 320px;
      margin: 0 auto;
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
      min-height: 300px;
  }
}
</style>