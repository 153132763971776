/**
 * @module location 
 *  Apis must need auth details to trigger url - AuthApi
 */

import Env from '@/env';
import NormalApis from '@/controllers/NormalApis';

export default {
   
    /**
     * Fetch by slug
     */
    fetchBySlug(businessId, slug) {
        const url = `${Env.locationUrl}/clients/businesses/${businessId}/locations/${slug}`;
        return NormalApis.getApi(url, {
          'api-token': Env.locationApiToken,
          'auth-token': Env.locationAuthToken,
        })
        .then(res => res)
        .catch(err => err);
    },
    

}