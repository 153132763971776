/**
 * @module NormalApis
 *  Apis do not need auth - NormalApis
 */
import axios from 'axios';

export default {
  /**
   * Call to an GET Authenticate api for response.
   * @function
   * @param {string} url - Url to get results
   * @returns {object} A promise that will return either a success object or an error object.
   */
  getApi: (url, headers = {}) => axios({
    method: 'GET',
    headers,
    url
  }),

  /**
 * Call to an POST api for response.
 * @function
 * @param {string} url - Url to get results
 * @param {object} data - accept objects
 * @returns {object} A promise that will return either a success object or an error object.
 */
  postApi: (url, headers = {}, data) => axios({
    method: 'POST',
    headers,
    url,
    data,
  }),

  /**
 * Call to an POST api for response.
 * @function
 * @param {string} url - Url to get results
 * @param {object} data - accept objects
 * @returns {object} A promise that will return either a success object or an error object.
 */
  patchApi: (url, headers = {}, data) => axios({
    method: 'PATCH',
    headers,
    url,
    data,
  }),

   /**
   * Call to an POST api for response.
   * @function
   * @param {string} url - Url to get results
   * @param {object} data - accept objects
   * @returns {object} A promise that will return either a success object or an error object.
   */
  putApi: (url, headers = {}, data) => axios({
    method: 'PUT',
    headers,
    url,
    data,
  }),
   /**
 * Call to an POST api for response.
 * @function
 * @param {string} url - Url to get results
 * @param {object} data - accept objects
 * @returns {object} A promise that will return either a success object or an error object.
 */
  deleteApi: (url, headers = {}, data) => axios({
    method: 'DELETE',
    headers,
    url,
    data,
  }),
};
